@use 'mixins' as *;

.content-container {
  margin-inline: auto;
  inline-size: calc(100vw - 48px);

  @include breakpoint-min(small) {
    inline-size: 62.8vw;
    max-inline-size: var(--content-width-narrow, 41.25rem);
  }

  &.wide {
    max-inline-size: var(--content-width-wide, 62.5rem);
    @include breakpoint-min(small) {
      max-inline-size: var(--content-width-wide, 62.5rem);
      inline-size: calc(100vw - 48px);
    }
  }
}
