@use 'mixins' as *;

/* Main */
:root {
  --header-height: 56px;
  --map-offset: 0;
  --map-width: 100%;
  --map-height: 500px;
  --map-height-desktop: 600px;
  --content-width-medium: 51.875rem;
}

// Font weights
:root {
  --font-weight-h1: 300;
  --font-weight-h2: 300;
  --font-weight-h3: 300;
  --font-weight-h4: 500;
  --font-weight-h5: 500;
  --font-weight-h6: 500;
  --font-weight-p1: 300;
  --font-weight-p2: 300;
  --font-weight-p3: 300;
  --font-weight-subheading: 300;
}

// Letter spacing
:root {
  --letter-spacing-h1: 0;
  --letter-spacing-h2: 0;
  --letter-spacing-h3: 0;
  --letter-spacing-h4: 0;
  --letter-spacing-h5: 0;
  --letter-spacing-h6: 0;
  --letter-spacing-p1: 0;
  --letter-spacing-p2: 0;
  --letter-spacing-p3: 0;
  --letter-spacing-subheading: 2.5px;
}

// Line height
@include portrait {
  :root {
    --line-height-h1: 1.2;
    --line-height-h2: 1.2;
    --line-height-h3: 1.2;
    --line-height-h4: 1.2;
    --line-height-h5: 1.2;
    --line-height-h6: 1.2;
    --line-height-p1: 1.5;
    --line-height-p2: 1.5;
    --line-height-p3: 1.5;
    --line-height-subheading: 1.4;
  }
}

@include landscape {
  :root {
    --line-height-h1: 1.2;
    --line-height-h2: 1.3;
    --line-height-h3: 1.3;
    --line-height-h4: 1.2;
    --line-height-h5: 1.2;
    --line-height-h6: 1.2;
    --line-height-p1: 1.5;
    --line-height-p2: 1.5;
    --line-height-p3: 1.5;
    --line-height-subheading: 1.4;
  }
}

// Font sizes
@include portrait {
  :root {
    --font-size-h1: 1.75rem;
    --font-size-h2: 1.25rem;
    --font-size-h3: 0.8125rem;
    --font-size-h4: 0.8125rem;
    --font-size-h5: 0.8125rem;
    --font-size-h6: 0.8125rem;
    --font-size-p1: 0.875rem;
    --font-size-p2: 1.25rem;
    --font-size-p3: 1.25rem;
    --font-size-subheading: 0.625rem;
  }
}

@include landscape {
  :root {
    --font-size-h1: 5rem;
    --font-size-h2: 2.875rem;
    --font-size-h3: 2rem;
    --font-size-h4: 2rem;
    --font-size-h5: 1.25rem;
    --font-size-h6: 1rem;
    --font-size-p1: 1.75rem;
    --font-size-p2: 1.25rem;
    --font-size-p3: 1rem;
    --font-size-subheading: 0.75rem;
  }
}

// Colors
:root {
  --color-hell-no: #ff1e1e;
  --color-hell-yeah: #67fc6d;
  --color-boxshadow-transparent: rgba(0, 0, 0, 0.1);
  --color-overlay-transparent: rgba(35, 33, 32, 0.3);
  --color-text-shadow-black: rgba(33, 33, 38, 0.8);
  --color-text-shadow-white: rgba(255, 255, 255, 0.8);

  // NEW GUIDELINES
  --color-black: #000000;
  --color-white: #ffffff;
  --color-gray1: #f6f5ef;
  --color-gray2: #e5e5e0;
  --color-gray3: #b8b6b1;
  --color-cta-blue: #5042f2;
  --color-dark-brown: #321919;
  --test: #e6e6e6;
}

// Font families
// * use the font-family variables in _app.tsx
// :root {
//   --font-family-regular: ;
//   --font-family: ;
// }

// Spacing
:root {
  --spacing-xl: 12rem;
  --spacing-large: 6rem;
  --spacing-medium: 3rem;
  --spacing-menu-height: 3.5rem;
  --spacing-regular: 1.5rem;
  --spacing-small: 1rem;
  --spacing-xregular: 2rem;
  --spacing-xsmall: 0.5rem;
  --spacing-xxsmall: 0.25rem;
  --spacing-xl-negative: -12rem;
  --spacing-large-negative: -6rem;
  --spacing-medium-negative: -3rem;
  --spacing-menu-height-negative: -3.5rem;
  --spacing-regular-negative: -1.5rem;
  --spacing-small-negative: -1rem;
  --spacing-xregular-negative: -2rem;
  --spacing-xsmall-negative: -0.5rem;
  --spacing-xxsmall-negative: -0.25rem;
}

*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  background-repeat: no-repeat; /* 2 */
}
html {
  font-size: 16px;
  font-weight: var(--font-weight-p3);
  min-height: 100vh;
}

body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-weight: var(--font-weight-p3);
}

#root {
  min-height: 100vh;
}

img {
  max-width: 100%;
}

.no-js img.lazyload {
  display: none;
}

.map {
  position: relative;
  width: 100%;
}
h1,
h2,
h3,
h4,
h6,
p,
a {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
  font-family: inherit;
  appearance: none;
  color: inherit;
}
button {
  font-family: inherit;
  appearance: none;
  border: none;
  background-color: transparent;
  outline: none;
}
